import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Api from './api.js'
Vue.prototype.$api = Api

import {Form, FormItem,Input,Button, Message,Container,Aside,Main,Dialog,Drawer,Col,Select,Option,MessageBox,Tree,Pagination,Popover,Dropdown,DropdownMenu,DropdownItem} from 'element-ui'
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Dialog)
Vue.use(Drawer)
Vue.use(Col)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tree);
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
import "@/assets/scss/style.scss"
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
