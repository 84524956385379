import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '../views/Login.vue';
import Index from '../views/Index.vue';
import Order from '../views/Order.vue';
import OrderInfo from '../views/OrderInfo.vue';
import Deposit from '../views/Deposit.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
      auth: true,
    },
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta: {
      title: '首页',
      auth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
      auth: true,
    },
  },
  {
    path: '/order/:t',
    name: 'Order',
    component: Order,
    meta: {
      title: '订单',
      auth: true,
    },
  },
  {
    path: '/orderInfo',
    name: 'OrderInfo',
    component: OrderInfo,
    meta: {
      title: '订单详情',
      auth: true,
    },
  },
  {
    path: '/deposit/:t',
    name: 'deposit',
    component: Deposit,
    meta: {
      title: '定金',
      auth: true,
    },
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  document.title = to.meta.title + '-收银系统' || '';
  next(true);
});

export default router;
