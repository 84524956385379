import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isPayDialog: false,//支付弹框
    isDistribute:false, //分配弹窗
    isCartDialog:false, //购物车弹窗
    isDepositDialog:false, //收取定金弹窗
    isDepositDistribute:false, //定金分配弹窗
  },
  mutations: {
    changeIsPayDialog(state, isOpen) {
      state.isPayDialog = isOpen;
    },
    changeIsDistribute(state, isOpen){
      state.isDistribute = isOpen;
    },
    changeIsCartDialog(state, isOpen){
      state.isCartDialog = isOpen;
    },
    changeIsDepositDialog(state,isOpen){
      state.isDepositDialog = isOpen
    },
    changeIsDepositDistribute(state,isOpen){
      state.isDepositDistribute = isOpen
    }
  },
  actions: {
    isPayDialog({commit}, isOpen) {
      commit('changeIsPayDialog', isOpen);
    },
    isDistribute({commit}, isOpen){
      commit('changeIsDistribute', isOpen);
    },
    isCartDialog({commit}, isOpen){
      commit('changeIsCartDialog', isOpen);
    },
    changeIsDepositDialog({commit},isOpen){
      commit('changeIsDepositDialog',isOpen)
    },
    changeIsDepositDistribute({commit},isOpen){
      commit('changeIsDepositDistribute',isOpen)
    }
  },
  getters: {

  },
});
