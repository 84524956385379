<template>
  <el-dialog
     :visible.sync="isPayDialog"
     width="40%"
     :before-close="handleClose">
    <div class="pay-dialog">
      <div class="pay-left"></div>
      <div class="pay-right">
        <div class="code-img">
          <img :src="qr_code" />
        </div>
        <h3 class="pay-name">扫码支付</h3>
        <p class="pay-number">{{order_no}}</p>
      </div>
      <p class="tips">请配合蒙特利斯pos机扫码支付</p>
    </div>
  </el-dialog>
</template>

<script>
  import {mapState,mapMutations,mapGetters,mapActions} from 'vuex'
  export default {
    name: 'payDialog',
    props:{
      qr_code:'',
      order_no:'',
      order_id:0,
      pay_type:{
        type:Number,
        default:0
      }
    },
    computed:{
      ...mapState({
        isPayDialog:state => state.isPayDialog
      })
    },
    data(){
      return{
        order_status:0,
        timer:null
      }
    },
    watch:{
      isPayDialog(val){
        if(val && !this.order_status){
          this.timer = setInterval(() => {
            this.getStatus()
          },1000)
        }else{
          clearInterval(this.timer)
        }
      }
    },
    methods:{
      ...mapMutations([
        'changeIsPayDialog'
      ]),
      handleClose() {
        this.changeIsPayDialog(false)
      },
      getStatus(){
        var data = {
          id:this.order_id,
          type:this.pay_type || 1
        }
        this.$api.payStatus(data).then(res => {
          if(res){
            this.order_status = res.status || false
            if(this.order_status){
              this.$message.success('支付成功')
              if(this.pay_type === 1){
                this.$router.push({
                  path:'/orderInfo',
                  query:{
                    id:this.order_id
                  }
                })
              }else{
                this.$emit('getList')
              }
              this.changeIsPayDialog(false)
              clearInterval(this.timer)
            }
          }
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .pay-dialog{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    padding: 0 60px 60px 60px;
    box-sizing: border-box;
    .pay-left{
      min-width: 230px;
      height: 354px;
      background: url('../assets/imgs/pos.png') no-repeat center center;
      background-size: cover;
    }
    .pay-right{
      flex: 1;
      padding-left: 30px;
      .code-img{
        width: 240px;
        height: 240px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .pay-name{
        text-align: center;
        font-size: 20px;
        color: #4E87F6;
        padding-top: 60px;
      }
      .pay-number{
        color: #333333;
        font-size: 18px;
        padding-top: 15px;
        text-align: center;
      }
    }
    .tips{
      text-align: center;
      color: #AEAEAE;
      font-size: 14px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
</style>
