<template>
  <div class="wrap wrap-index">
    <div class="container">
      <div class="cate-wrap">
        <div class="logo">
          <img src="../../public/images/logo.svg"/>
        </div>
        <ul class="aside-list">
          <li v-for="(item,index) in goodList" :key="index" @click="jump(index)"
              :class="currentIndex === index ? 'active' :''"> {{item.name}}
          </li>
        </ul>
      </div>
      <div class="list-wrap">
        <div v-for="(cate,index) in goodList" :key="index" :id="'id-'+index">
          <h1 class="cate-name-all">{{cate.name}}</h1>
          <div class="goods">
            <ul class="goods-list">
              <li class="goods-item" v-for="(item,i) in cate.goods" :key="i">
                <div class="cover-img">
                  <img :src="item.pc_cover">
                </div>
                <h3 class="cate-name">{{item.title}}</h3>
                <div class="info">
                  <p class="manner">{{item.sub_title}}</p>
                  <p class="manner">规格：{{item.size || '--'}}</p>
                  <h3 class="price">
                    <p v-if="!item.is_custom">￥{{item.price}}<span
                       class="unit">元/{{item.unit}}</span></p>
                    <p v-else>定制产品</p>
                  </h3>
                  <span class="join-cart" @click="getGoodSku(item.id)"></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="function-list">
        <div class="item" @click="changeIsCartDialog(true)">
          <i class="num" v-if="cartList.length">{{cartList.length}}</i>
          <i class="icon"><img src="../../public/images/cart-icon.svg"/></i>
          <p class="text">购物车</p>
        </div>
        <div class="item">
          <router-link :to="{path:'/order/0'}">
            <i class="icon"><img src="../../public/images/order-icon.svg"/></i>
            <p class="text">订单</p>
          </router-link>
        </div>
        <el-dropdown placement="left" @command="handleCommand">
          <div class="item">
            <i class="icon"><img src="../../public/images/user-icon.svg"/></i>
            <p class="text">{{userName}}</p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">退出登录</el-dropdown-item>
              <el-dropdown-item command="b">修改密码</el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
        <div class="item">
          <router-link :to="{path:'/deposit/0'}">
            <i class="icon"><img src="../../public/images/deposit-icon.png"></i>
            <p class="text">收定金</p>
          </router-link>
        </div>
      </div>
    </div>
    <!--产品规格弹窗-->
    <el-dialog
       :visible.sync="cartDialog"
       v-if="cartDialog"
       width="50%"
       :before-close="handleClose">
      <div class="add-cart-dialog">
        <div class="cover-img"><img :src="skuSelect.image"/></div>
        <div class="info-wrap">
          <p class="name">{{skuInfo.title}}</p>
          <p class="desc">{{skuInfo.size}}</p>
          <p class="price" v-if="skuInfo.is_custom">定制产品请输入价格</p>
          <p class="price" v-else>¥{{skuSelect.sale_price}} <span class="unit">元/{{skuInfo.unit}}</span></p>
          <div class="specs-wrap">
            <p class="specs-name">{{skuSelect['key'] || '属性'}}</p>
            <ul class="specs-list">
              <li :class="{'active': item.id == skuSelect.id}" @click.stop="change(item)"
                  v-for="(item,index) in skuInfo.sku" :key="index">{{item.value}}
              </li>
            </ul>
          </div>
          <div class="specs-wrap">
            <p class="specs-name">{{skuInfo.is_custom ? '金额' : '数量'}}</p>
            <ul class="count">
              <li v-if="skuInfo.is_suit == 1"><span class="num-dec" @click.stop="reduceNum"></span></li>
              <li><span class="num" v-if="number">{{number}}</span></li>
              <li v-if="skuInfo.is_suit == 1"><span class="num-add" @click.stop="addNum"></span></li>
              <li v-if="skuInfo.is_suit == 2"><span class="num-edit" @click.stop="editNum"></span></li>
            </ul>
          </div>
          <span class="add-cart-btn" @click="addSubmit">加入购物车</span>
        </div>
      </div>
    </el-dialog>
    <!--购物车弹窗-->
    <cart-app @getPayInfo="getPayInfo" @reSetList="reSetList"></cart-app>
    <!--支付弹窗-->
    <payApp :qr_code="qr_code" :order_no="order_no" :order_id="order_id"></payApp>
    <!--修改密码弹窗-->
    <el-dialog
       title="手机重置密码"
       :visible.sync="passwordDialog"
       width="22%"
       :before-close="handleClose">
      <el-form ref="form">
        <el-form-item>
          <el-input placeholder="手机号" v-model="telphone" disabled clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="验证码" v-model="code" clearable></el-input>
          <span class="get-code" :class="{'disabled': codeTimer}" @click.stop="sendCode">{{codeText}}</span>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入新密码" v-model="password" show-password clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%" @click="passwordSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import payApp from '../components/payApp';
  import cartApp from '../components/cartApp';
  import {mapMutations} from 'vuex';

  export default {
    components: {
      payApp,
      cartApp
    },
    data() {
      return {
        goodList: [],
        cartList: [],
        currentIndex: 0,
        listBoxState: true,
        cartDialog: false,
        skuInfo: {},
        skuSelect: {},
        number: 1,

        qr_code: '',
        order_no: '',
        order_id: 0,
        order_status: false,

        passwordDialog: false,
        telphone: '',
        password: '',
        code: '',
        codeText: '获取验证码',
        codeTimer: 0,
        timer: null,
        sending: false,
        userName: '',
      };
    },
    watch: {
      codeTimer(val) {
        if (val > 0) {
          let s = val > 9 ? val : `0${val}`;
          this.codeText = `${s}秒后重发`;
        }
        else {
          this.codeText = '重新获取';
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        let timeId;
        window.addEventListener('scroll', () => {
          clearTimeout(timeId);
          timeId = setTimeout(() => {
            this.scrollToTop();
          }, 100);
        }, true);
      });
    },
    methods: {
      ...mapMutations([
        'changeIsPayDialog',
        'changeIsCartDialog',
        'changeIsDepositDialog',
      ]),
      getList() {
        this.$api.goodsIndex().then(res => {
          if (res) {
            this.goodList = res || [];
          }
        });
      },
      getGoodSku(id) {
        this.$api.goodsSku(id).then(res => {
          if (res) {
            this.skuInfo = res || {};
            this.change(this.skuInfo.sku[0] || {});
            this.cartDialog = true;
          }
        });
      },
      change(item) {
        this.skuSelect = item;
        this.number = 1;
      },
      reduceNum() {
        if (this.skuInfo.is_suit == 2) {
          //this.good.status = true
          //this.bKeyboardFlag(this.good)
        }
        else {
          if (this.number <= 1) {
            this.$message.error('数量不能再减啦');
          }
          else {
            this.number--;
          }
        }
      },
      addNum() {
        if (this.skuInfo.is_suit == 2) {
          //this.good.status = true
          //this.bKeyboardFlag(this.good)
        }
        else {
          this.number++;
        }
      },
      editNum() {
        this.$prompt('请输入内容', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: this.number,
        }).then(({value}) => {
          this.number = value;
        });
      },
      addSubmit() {
        if (this.skuSelect.id === 0) {
          this.$message.error('请选择商品属性');
          return false;
        }
        if (this.number === 0) {
          this.$message({
            message: this.good.is_custom ? '请输入商品金额' : '请添加商品数量',
            type: 'error',
          });
          return false;
        }
        let data = {
          is_custom: this.skuInfo.is_custom,
          title: this.skuInfo.title,
          sub_title: this.skuInfo.sub_title,
          is_suit: this.skuInfo.is_suit,
          size: this.skuInfo.size,
          unit: this.skuInfo.unit,
          id: this.skuInfo.id,
          num: this.number,
          cover_images: this.skuSelect.image,
          sku: this.skuSelect,
        };
        if (data.is_custom === 1) {
          data.sku.sale_price = data.num;
          data.num = 1;
        }
        var cartgoods = JSON.parse(localStorage.getItem('cartGoods') || '[]');
        let hasCart = cartgoods.some(item => {
          if (item.sku.id === this.skuSelect.id) {
            item.num++;
            return true;
          }
          else {
            return false;
          }
        });
        if (!hasCart) {
          cartgoods.push(JSON.parse(JSON.stringify(data)));
        }
        localStorage.setItem('cartGoods', JSON.stringify(cartgoods));
        this.skuInfo.num = 1;
        this.$message.success('添加成功');
        this.reSetList()
        this.cartDialog = false;
      },
      getPayInfo(res) {
        this.qr_code = res.qr_code || '';
        this.order_no = res.order_no || '';
        this.order_id = res.id || 0;
        this.changeIsCartDialog(false);
        this.reSetList()
        localStorage.removeItem('cartGoods');
        this.changeIsPayDialog(true);
      },
      onSelected(data) {
        this.city = data.province.value + '-' + data.city.value + '-' + data.area.value;
        this.cityShow = false;
      },
      handleClose(done) {
        done();
      },
      jump(index) {
        this.currentIndex = index;

        this.$el.querySelector(`#id-${index}`).scrollIntoView({
          behavior: 'smooth',  // 平滑过渡
          block: 'start',  // 上边框与视窗顶部平齐。默认值
        });
        this.listBoxState = false;

        let timeId;
        clearTimeout(timeId);
        timeId = setTimeout(() => {
          this.listBoxState = true;
        }, 200);
      },
      scrollToTop() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (this.listBoxState) {
          this.goodList.map((v, i) => {
            var offsetTop = document.getElementById(`id-${i}`).offsetTop - 40;
            var scrollHeight = document.getElementById(`id-${i}`).scrollHeight;
            if (scrollTop >= offsetTop && scrollTop <= (offsetTop + scrollHeight)) {
              this.currentIndex = i;
            }
          });
        }
      },
      exitLogin() {
        this.$confirm('确定退出当前账号吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$api.userOut().then(res => {
            if (res) {
              localStorage.removeItem('cartGoods');
              this.$router.push({
                path: '/login',
              });
            }
          });
        });
      },
      intVal() {
        setTimeout(() => {
          this.codeTimer--;
          if (this.codeTimer > 0) {
            this.intVal();
          }
        }, 1000);
      },
      checkTel() {
        return /^1[3456789]\d{9}$/.test(this.telphone);
      },
      sendCode() {
        if (this.codeTimer) {
          return;
        }

        if (!this.checkTel()) {
          this.$message.error('手机号有误');
          return;
        }

        if (this.sending) {
          return;
        }

        this.sending = true;

        this.$api.userCode(this.telphone, 2).then(res => {
          if (res) {
            this.codeTimer = 60;
            this.intVal();
          }
          this.sending = false;
        });
      },
      passwordSubmit() {
        var data = {
          password: this.password,
          tel: this.telphone,
          code: this.code,
        };
        this.$api.userEdit(data).then(res => {
          if (res) {
            this.$message.success('修改成功,即将重新登录');
            this.$router.push({
              path: '/login',
            });
          }
        });
      },
      editPass() {
        this.$api.userInfo().then(res => {
          if (res) {
            this.telphone = res.tel || '';
            this.userName = res.name || '';
          }
        });
      },
      handleCommand(command) {
        if (command === 'a') {
          this.exitLogin();
          return;
        }
        if (command === 'b') {
          this.passwordDialog = true;
          return;
        }
      },
      reSetList(){
        this.cartList = JSON.parse(localStorage.getItem('cartGoods') || '[]');
      }
    },
    created() {
      this.getList();
      this.editPass();
      this.reSetList()
    },
  };
</script>

<style lang="scss">
  .wrap-index {
    height: 100vh;
    width: 100%;

    .container {
      height: 100%;
    }

    .cate-wrap {
      width: 84px;
      background: #fff;
      padding: 30px 0;
      border-radius: 0 20px 20px 0;
      position: fixed;
      left: 0;
      top: 50%;
      transform: translateX(0) translateY(-50%);
      -webkit-transform: translateX(0) translateY(-50%);
      box-sizing: border-box;
    }

    .list-wrap {
      margin-left: 120px;
      margin-top: 40px;
    }

    .logo {
      width: 44px;
      height: 44px;
      display: block;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .aside-list {
      padding-top: 20px;
      flex: 1;

      li {
        text-align: center;
        font-size: 14px;
        height: 48px;
        line-height: 48px;
        color: #333333;
        cursor: pointer;
        position: relative;

        &.active {
          color: #4E87F6;
          font-weight: bold;

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 2px;
            height: 20px;
            background: #4E87F6;
            right: 0;
            top: 50%;
            margin-top: -10px;
          }
        }
      }
    }


    .el-main {
      padding-top: 40px;
    }

    .cate-name-all {
      font-size: 24px;
      color: #333;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      padding-top: 20px;
    }

    .goods-list {
      font-size: 0;
      line-height: 0;

      .goods-item {
        width: 228px;
        height: 343px;
        display: inline-block;
        margin-bottom: 14px;
        background: #fff;
        vertical-align: top;
        padding: 11px 14px;
        box-sizing: border-box;
        margin-right: 16px;

        .cate-name {
          font-size: 16px;
          color: #333333;
          height: 32px;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .cover-img {
          height: 200px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          position: relative;

          .manner {
            color: #bfbfbf;
            font-size: 14px;
            height: 28px;
            line-height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }

          .price {
            font-size: 16px;
            color: #333;
            height: 14px;
            line-height: 14px;
            padding-top: 10px;
            font-weight: bold;

            .unit {
              color: #999999;
              font-size: 14px;
              padding-left: 5px;
              font-weight: normal;
            }
          }

          .join-cart {
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: -5px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            background: url("../assets/imgs/add-cart-icon.png") no-repeat center center;
            background-size: auto 100%;
          }
        }
      }
    }

    .add-cart-dialog {
      display: flex;
      padding-bottom: 30px;

      .cover-img {
        width: 320px;
        height: 320px;
        background: #fff;

        img {
          width: 100%;
        }
      }

      .info-wrap {
        padding: 0 60px;

        .name {
          font-size: 18px;
          line-height: 25px;
          color: #333;
        }

        .desc {
          color: #999;
          font-size: 14px;
          line-height: 30px;
        }

        .price {
          font-size: 18px;
          padding: 20px 0;
          color: #333;

          .unit {
            font-size: 14px;
            color: #666;
          }
        }

        .specs-wrap {
          margin-bottom: 10px;

          .specs-name {
            font-size: 16px;
            color: #666;
            padding: 10px 0;
          }

          .specs-list {
            li {
              border: 1px solid #EEEEEE;
              height: 32px;
              line-height: 32px;
              text-align: center;
              padding: 0 15px;
              font-size: 14px;
              color: #666;
              display: inline-block;
              margin-right: 16px;
              border-radius: 3px;
              cursor: pointer;

              &.active {
                border: 1px solid #4E87F6;
                background: rgba(78, 135, 246, 0.08);
                color: #4E87F6;
              }
            }
          }

          .count {
            overflow: hidden;
            font-size: 0;
            line-height: 0;
            vertical-align: middle;
            display: inline-block;
            border: 1px solid #eee;

            li {
              display: inline-block;
              vertical-align: top;

              span {
                display: inline-block;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-size: 14px;
                color: #333;
                cursor: pointer;
                background: #f8f8f8;
                position: relative;

                &.num-add {
                  background: url("../assets/imgs/add-icon.png") no-repeat center center;
                  background-size: cover;
                }

                &.num-dec {
                  background: url("../assets/imgs/dec-icon.png") no-repeat center center;
                  background-size: cover;
                }

                &.num-edit {
                  background: url("../assets/imgs/edit-icon.png") no-repeat center center;
                  background-size: cover;
                }
              }

              .num {
                width: auto;
                padding: 0 15px;
                border-right: 0;
                border-left: 0;
              }
            }
          }
        }

        .add-cart-btn {
          width: 100px;
          height: 32px;
          line-height: 32px;
          background: #4E87F6;
          text-align: center;
          color: #fff;
          margin-top: 40px;
          border-radius: 3px;
        }
      }
    }


    .function-list {
      position: fixed;
      right: 20px;
      bottom: 50px;
      width: 68px;
      border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
      background: #fff;
      text-align: center;
      padding: 10px 0;
      list-style: none;
      display: flex;
      flex-direction: column;

      .item {
        padding: 8px 0;
        flex: 1;
        position: relative;
        cursor: pointer;

        .num {
          position: absolute;
          top: 0;
          right: 8px;
          width: 22px;
          height: 16px;
          border-radius: 8px;
          background: #F52A2A;
          text-align: center;
          line-height: 16px;
          color: #fff;
          font-size: 12px;
          font-style: normal;
        }

        .icon {
          width: 22px;
          height: 22px;
          display: block;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          color: #666666;
          font-size: 12px;
          padding-top: 5px;
        }
      }
    }

    .get-code {
      position: absolute;
      right: 10px;
      font-size: 12px;
      color: #409EFF;

      &.disabled {
        color: #aaa;
      }
    }


  }
</style>
