<template>
  <el-drawer
     :visible.sync="isDepositDialog"
     direction="rtl"
     :size="500"
     :before-close="handleClose">
    <div class="deposit-dialog">
      <div class="middle">
        <div class="user-info">
          <h3 class="title" style="padding-bottom: 20px">收取定金</h3>
          <el-form ref="form"
                   size="medium"
                   label-width="0">
            <el-form-item style="width: 48%;display: inline-block">
              <el-input v-model="name" placeholder="请输入客户姓名"></el-input>
            </el-form-item>
            <el-form-item style="width: 48%;display: inline-block;margin-left: 4%">
              <el-input type="tel" v-model="tel" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input type="tel" v-model="city" @focus="cityShow=true" placeholder="请选择省/市/区"></el-input>
              <div class="divwrap" v-if="cityShow">
                <v-distpicker type="mobile" @selected="onSelected"></v-distpicker>
              </div>
            </el-form-item>
            <el-form-item>
              <el-input type="tel" v-model="address" placeholder="请输入小区名称"></el-input>
            </el-form-item>
            <el-form-item class="input-item">
              <span class="label">住所信息</span>
              <ul class="address-list">
                <li>
                  <el-input v-model="floor" placeholder="XX"></el-input>
                  <span class="uit">号楼</span>
                </li>
                <li>
                  <el-input v-model="unit" placeholder="XX"></el-input>
                  <span class="uit">单元</span>
                </li>
                <li>
                  <el-input v-model="room" placeholder="XX"></el-input>
                  <span class="uit">室</span>
                </li>
              </ul>
            </el-form-item>
            <el-form-item style="width: 48%;display: inline-block;position: relative">
              <el-input v-model="money" placeholder="定金金额"></el-input>
              <span class="unit">元</span>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="footer">
        定金：<span class="total"><i>¥</i> {{money ? money : '0'}}</span>
        <el-button class="order-btn" @click="submit" :loading="submitLoading" :disabled="submission?true:false">
          {{submission?'支付中':'确定'}}
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import {mapMutations, mapState} from 'vuex';
  import VDistpicker from 'v-distpicker'
  export default {
    name:'depositApp',
    components:{
      VDistpicker
    },
    data(){
      return {
        name:'',
        tel:'',
        cityShow:false,
        city:'',
        address:'',
        floor:'',
        unit:'',
        room:'',
        money:'',
        submission:false,
        submitLoading:false
      }
    },
    computed:{
      ...mapState({
        isDepositDialog:state => state.isDepositDialog
      }),
    },
    methods:{
      ...mapMutations([
        'changeIsDepositDialog'
      ]),
      handleClose(){
        this.changeIsDepositDialog(false)
      },
      onSelected(data) {
        this.city = data.province.value + '-' + data.city.value+ '-' + data.area.value
        this.cityShow=false
      },
      submit(){
        if (this.name === '' || this.tel === '' || this.floor === '' || this.address === '' || this.unit === '' || this.room === '' || this.city === '' || this.money === '') {
          this.$message.error('请补全信息');
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.tel)) {
          this.$message.error('手机号码有误');
          return;
        }
        var data = {
          name:this.name,
          tel:this.tel,
          district:this.city,
          region:this.address,
          floor:this.floor,
          unit:this.unit,
          room:this.room,
          price:this.money
        }
        this.submission = true
        this.submitLoading = true
        this.$api.depositCollect(data).then(res => {
          if(res){
            res.type = 2
            this.$emit('getPayInfo',res)
            this.orderBtnLoading = false
            this.submission = false;
          }else{
            setTimeout(()=>{
              this.orderBtnLoading = false
              this.submission = false;
            },2000)
          }
        });
      }
    }
  }
</script>

<style lang="scss">
  .deposit-dialog{
    display: flex;
    flex-direction: column;
    height: 100%;
    .middle {
      flex: 1;
      padding: 0 20px;
      overflow: auto;
    }
    .unit{
      position: absolute;
      top: 0;
      right: 10px;
    }
    .footer {
      height: 54px;
      line-height: 54px;
      padding: 0 16px;
      border-top: 1px solid #eee;
      font-size: 16px;
      color: #333;
      position: relative;

      .total {
        font-size: 20px;
        color: #F52A2A;
        font-weight: bold;

        i {
          font-size: 14px;
          font-style: normal;
        }
      }

      .order-btn {
        width: 74px;
        height: 32px;
        line-height: 32px;
        border-radius: 3px;
        background: #4E87F6;
        font-size: 14px;
        color: #fff;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -16px;
        text-align: center;
        border: 0;
        padding: 0;
        outline: 0;
        cursor: pointer;
      }
    }
  }
</style>
