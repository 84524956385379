<template>
  <el-drawer
     :visible.sync="isCartDialog"
     direction="rtl"
     :size="500"
     :before-close="handleClose">
    <div class="cart-dialog">
      <div class="middle">
        <div class="user-info">
          <h3 class="title" style="padding-bottom: 20px">收货信息</h3>
          <el-form ref="form"
                   size="medium"
                   label-width="0">
            <el-form-item style="width: 48%;display: inline-block">
              <el-input v-model="name" placeholder="请输入客户姓名"></el-input>
            </el-form-item>
            <el-form-item style="width: 48%;display: inline-block;margin-left: 4%">
              <el-input type="tel" v-model="tel" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input type="tel" v-model="city" @focus="cityShow=true" placeholder="请选择省/市/区"></el-input>
              <div class="divwrap" v-if="cityShow">
                <v-distpicker type="mobile" @selected="onSelected"></v-distpicker>
              </div>
            </el-form-item>
            <el-form-item>
              <el-input type="tel" v-model="address" placeholder="请输入小区名称"></el-input>
            </el-form-item>
            <el-form-item class="input-item">
              <span class="label">住所信息</span>
              <ul class="address-list">
                <li>
                  <el-input v-model="floor" placeholder="XX"></el-input>
                  <span class="uit">号楼</span>
                </li>
                <li>
                  <el-input v-model="unit" placeholder="XX"></el-input>
                  <span class="uit">单元</span>
                </li>
                <li>
                  <el-input v-model="room" placeholder="XX"></el-input>
                  <span class="uit">室</span>
                </li>
              </ul>
            </el-form-item>
          </el-form>
        </div>
        <div class="good-info">
          <h3 class="title" style="padding-top: 10px;">商品信息</h3>
          <div class="good-list" v-if="cartList.length">
            <div class="cart-item" v-for="(item,i) in cartList" :key="i">
              <span class="del-icon" @click="romoveCartGood(i)">移除</span>
              <div class="cover-img">
                <img :src="item.cover_images"/>
              </div>
              <div class="material-info">
                <p class="name">{{item.title}}</p>
                <p class="desc">已选择：{{item.sku.key}} {{item.sku.value}}</p>
                <P class="price"><i>¥</i> {{item.sku.sale_price}}<span class="unit">元/{{item.unit}}</span></P>
              </div>
              <ul class="count" v-if="!item.is_custom">
                <li><span class="num-jia" @click.stop="cartReduce(item,i)">-</span></li>
                <li><span class="num">{{item.num}}</span></li>
                <li><span class="num-jia" @click.stop="cartAdd(item)">+</span></li>
              </ul>
            </div>
          </div>
          <div class="empty-box" v-else>
            <img src="../../public/images/empty.png"/>
            <p class="empty-text">购物车暂时没有商品</p>
          </div>
        </div>
        <div class="deposit-list">
          <div class="deposit-item" v-for="(item,index) in depositList" :key="index" :class="{'active':item.select}" @click.stop="chooseDeposit(item)">
            <span class="checked"></span>
            <div class="name-box">
              <p class="name">{{item.name}} {{item.tel}}</p>
              <p class="collect-name">收款人员：{{item.collection_name}}</p>
            </div>
            <p class="price">收取定金：¥{{item.price}}</p>
            <p class="time">支付时间：{{item.pay_at}}</p>
          </div>
        </div>
      </div>
      <div class="footer">
        合计：<span class="total"><i>¥</i> {{totalMoney}}</span>
        <el-button class="order-btn" @click="goOrder" :loading="orderBtnLoading" :disabled="submission?true:false">
          {{submission?'提交':'去下单'}}
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import {mapState,mapMutations,mapGetters,mapActions} from 'vuex'
  import VDistpicker from 'v-distpicker'
  export default {
    name: 'cartApp',
    components:{
      VDistpicker
    },
    data(){
      return{
        cartList:[],
        name: '',
        tel: '',
        city: '',
        address: '',
        floor:'',
        unit:'',
        room:'',
        cityShow:false,
        submission:false,
        orderBtnLoading:false,
        depositList:[],
        deposit_id:[]
      }
    },
    watch:{
      isCartDialog(val){
        if(val){
          this.cartList = JSON.parse(localStorage.getItem('cartGoods') || '[]');
        }
      },
      tel(val){
        var isTel = /^1[3456789]\d{9}$/.test(val)
        if(isTel){
          this.$api.orderDeposit(val).then(res => {
            res.map(item => {
              item.select = 0
            })
            this.depositList = res || []
          })
        }else{
          this.depositList = []
        }
      }
    },
    computed:{
      ...mapState({
        isCartDialog:state => state.isCartDialog
      }),
      totalMoney() {
        let money = 0;
        this.cartList.map(item => {
          money += item.num * item.sku.sale_price;
        });
        return money;
      },
    },
    methods:{
      ...mapMutations([
        'changeIsCartDialog'
      ]),
      handleClose() {
        this.changeIsCartDialog(false)
      },
      onSelected(data) {
        this.city = data.province.value + '-' + data.city.value+ '-' + data.area.value
        this.cityShow=false
      },
      romoveCartGood(i) {
        this.$confirm('确认删除当前产品吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.cartList.splice(i, 1);
          localStorage.setItem('cartGoods', JSON.stringify(this.cartList));
          this.$emit('reSetList')
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        });
      },
      cartReduce(item, i) {
        if (item.num <= 1) {
          this.romoveCartGood(i);
        }
        else {
          item.num--;
        }
        localStorage.setItem('cartGoods', JSON.stringify(this.cartList));
        this.$emit('reSetList')
      },
      cartAdd(item) {
        item.num++;
        localStorage.setItem('cartGoods', JSON.stringify(this.cartList));
        this.$emit('reSetList')
      },
      goOrder() {
        if (this.name === '' || this.tel === '' || this.distirct === '' || this.address === '') {
          this.$message.error('请补全信息');
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.tel)) {
          this.$message.error('手机号码有误');
          return;
        }
        let nums = 0;
        let goods = [];
        this.cartList.map(item => {
          if (item.is_custom) {
            nums = parseInt(item.sku.sale_price);
          }
          else {
            nums = item.num;
          }
          goods.push({sku_id: item.sku.id, num: nums});
        });
        var data = {
          tel: this.tel,
          name: this.name,
          district: this.city,
          address: this.address,
          floor:this.floor,
          unit:this.unit,
          room:this.room,
          price: this.totalMoney,
          data: JSON.stringify(goods),
          deposit_id:this.deposit_id.join(',')
        };
        this.orderBtnLoading = true
        this.submission = true;
        this.$api.paySubmit(data).then(res => {
          if(res){
            localStorage.clearItem('cartGoods')
            this.$emit('getPayInfo',res)
            this.orderBtnLoading = false
            this.submission = false;
          }else{
            setTimeout(()=>{
              this.orderBtnLoading = false
              this.submission = false;
            },2000)
          }
        });
      },
      chooseDeposit(item){
        if (!item.select) {
          this.deposit_id.push(item.id);
          item.select = 1;
        }else {
          if (item.select === 1) {
            var index = this.deposit_id.indexOf(item.id);
            this.deposit_id.splice(index, 1);
            item.select = 0;
          }
        }
      }
    },
    created() {
      this.cartList = JSON.parse(localStorage.getItem('cartGoods') || '[]');
    }
  };
</script>

<style lang="scss">
  .divwrap {
    height: 200px;
    width: 100%;
    position: absolute;
    z-index: 99;
    overflow-y: auto;
    border: 1px solid #eee;
    border-top: 0;
    background: #fff;
    top: 40px;
    left: 0;
    li {
      color: #666;
    }
  }
  .input-item{
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    .el-form-item__content{
      display: flex;
      .label{
        width: 4em;
        padding-left: 18px;
      }
    }
    .el-input__inner{
      border: 0 !important;
      text-align: right;

    }
    .address-list{
      list-style: none;
      display: flex;
      flex: 1;
      padding: 0 10px;
      li{
        display: flex;
        .el-input{
          flex: 1;
        }
        .uit{
          width: 2em;
          text-align: center;
        }
      }
    }
  }
  .cart-dialog {
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
      font-size: 16px;
      color: #333;
    }

    .middle {
      flex: 1;
      padding: 0 20px;
      overflow: auto;
    }

    .good-info {
      .cart-item {
        font-size: 0;
        line-height: 0;
        background: #fff;
        display: flex;
        position: relative;
        padding: 15px 0;

        .del-icon {
          position: absolute;
          background: url("../assets/imgs/del_icon.png") no-repeat center center;
          background-size: auto 100%;
          width: 20px;
          height: 20px;
          top: 20px;
          right: 0;
          z-index: 11;
        }

        .cover-img {
          display: inline-block;
          width: 88px;
          height: 88px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .material-info {
          display: inline-block;
          vertical-align: top;
          flex: 1;
          padding: 0 12px;
          position: relative;

          .name {
            font-size: 14px;
            line-height: 28px;
            height: 28px;
            color: #333;
          }

          .desc {
            font-size: 14px;
            line-height: 28px;
            color: #999;
            background: #f2f2f2;
            text-align: left;
            height: 28px;
            box-sizing: border-box;
            padding: 0 5px;
            display: inline-block;
          }

          .price {
            color: #333;
            font-size: 14px;
            padding-top: 15px;
            font-weight: bold;
            height: 14px;
            line-height: 14px;

            .unit {
              color: #666;
              font-size: 10px;
              padding-left: 15px;
              font-weight: normal;
            }
          }
        }

        .count {
          overflow: hidden;
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          display: inline-block;
          position: absolute;
          right: 0;
          bottom: 15px;

          li {
            display: inline-block;
            vertical-align: top;

            span {
              display: inline-block;
              width: 24px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              font-size: 14px;
              color: #333;
              cursor: pointer;
              background: #f8f8f8;
              position: relative;
            }

            .num {
              width: 36px;
              border-right: 0;
              border-left: 0;
              min-height: 24px;
            }
          }
        }
      }
    }

    .deposit-list{
      padding: 10px 0 30px 0;
    }
    .deposit-item{
      border: 1px solid #EEEEEE;
      margin-top: 10px;
      padding: 10px 20px 10px 34px;
      position: relative;
      &.active{
        .checked{
          background: url("../assets/imgs/check-icon.png") no-repeat center center;
          background-size: cover;
        }
      }
      .checked{
        width: 14px;
        height: 14px;
        background: url("../assets/imgs/no-check-icon.png") no-repeat center center;
        background-size: cover;
        position: absolute;
        top: 13px;
        left: 10px;
        cursor: pointer;
      }
      .name-box{
        display: flex;
        p{
          flex: 1;
          font-size: 14px;
          height: 20px;
          line-height: 20px;
          &.name{
            color: #333;
          }
          &.collect-name{
            color: #999999;
            text-align: right;
          }
        }
      }
      .price{
        color: #666666;
        font-size: 12px;
        padding: 12px 0 4px;
      }
      .time{
        color: #666666;
        font-size: 12px;
      }
    }

    .empty-box {
      img {
        width: 180px;
        height: 90px;
        display: block;
        margin: 100px auto 0;
      }

      .empty-text {
        font-size: 14px;
        color: #999;
        text-align: center;
        padding-top: 20px;
      }
    }

    .footer {
      height: 54px;
      line-height: 54px;
      padding: 0 16px;
      border-top: 1px solid #eee;
      font-size: 16px;
      color: #333;
      position: relative;

      .total {
        font-size: 20px;
        color: #F52A2A;
        font-weight: bold;

        i {
          font-size: 14px;
          font-style: normal;
        }
      }

      .order-btn {
        width: 74px;
        height: 32px;
        line-height: 32px;
        border-radius: 3px;
        background: #4E87F6;
        font-size: 14px;
        color: #fff;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -16px;
        text-align: center;
        border: 0;
        padding: 0;
        outline: 0;
        cursor: pointer;
      }
    }
  }
  .el-message-box__btns button.el-button--primary{
    background: #4E87F6;
    border-color: #4E87F6;
  }
</style>
