<template>
  <div class="wrap-order">
    <div class="header">
      <div class="content">
        <router-link to="/index" class="logo">
          <img src="../../public/images/logo_02.svg">
          <span class="text">收银</span>
        </router-link>
        <ul class="fun-list">
          <li @click="changeIsCartDialog(true)">
            <span class="icon"><img src="../../public/images/cart-icon.svg"></span>
            购物车
          </li>
          <li>
            <span class="icon"><img src="../../public/images/user-icon.svg"></span>
            {{userName}}
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <div class="order-box">
        <router-link :to="{path:'/index'}" class="back-text">返回首页</router-link>
        <ul class="order-nav">
          <li v-for="(item,i) in cate">
            <router-link :to="{path:'/order/'+item.type}">
              {{item.name}}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="order-list" v-if="list.length && !loading">
        <table cellspacing="0">
          <thead>
          <tr>
            <th>商品信息</th>
            <th style="width: 120px;">创业者信息</th>
            <th style="width: 120px;">合伙人信息</th>
            <th style="width: 120px;">客户信息</th>
            <th style="width:200px;">金额</th>
            <th style="width: 80px;">操作</th>
          </tr>
          </thead>
          <tbody v-for="item in list">
          <tr class="sep-row">
            <td colspan="6"></td>
          </tr>
          <tr class="order-num">
            <td colspan="6">
              <p class="time">{{item.create_at}}</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              订单号：{{item.number}}
              <span class="order-status">{{item.status_name}}</span>
            </td>
          </tr>
          <tr class="order-detail" v-for="(i,j) in item.order_list">
            <td>
              <div class="goods-item">
                <div class="p-img">
                  <img :src="i.cover_img">
                </div>
                <div class="p-msg">
                  <div class="p-name">{{i.goods_name}}</div>
                  <div class="p-area">{{i.number}}</div>
                  <div class="p-name">单价：¥ {{i.price}} &nbsp; &nbsp;&nbsp; 数量：{{i.num}} &nbsp; &nbsp;&nbsp;小计：¥
                    {{i.total_price}}
                  </div>
                </div>
              </div>
            </td>
            <td colspan="1" :rowspan="item.order_list.length" v-if="j==0">
              {{item.pioneer.name}}<span class="tag" v-if="item.pioneer.name">创业者</span><br/>{{item.pioneer.tel}}
            </td>
            <td colspan="1" :rowspan="item.order_list.length" v-if="j==0">
              {{item.partner.name}}<span class="tag" v-if="item.partner.name">合伙人</span><br/>{{item.partner.tel}}
            </td>
            <td colspan="1" :rowspan="item.order_list.length" v-if="j==0">
              {{item.user.name}}<span class="tag" v-if="item.user.name">客户</span><br/>{{item.user.tel}}
            </td>
            <td colspan="1" :rowspan="item.order_list.length" v-if="j==0">
              {{item.status_name}}<br/>
              ¥ {{item.not_price}}<br/>
              <span style="color: #666" v-if="item.deposit_price">已付定金：¥{{item.deposit_price}}</span>
            </td>
            <td colspan="1" :rowspan="item.order_list.length" v-if="j==0">
              <div class="opearte">
                <el-button class="confirm-btn" :disabled="submission?true:false" v-if="item.status === 10"
                           @click="goPay(item.id)">支付
                </el-button>
                <el-button class="confirm-btn" :disabled="submission?true:false" v-if="item.status === 20"
                           @click="goDistribute(item.id)">分配
                </el-button>
                <router-link :to="{path:'/orderInfo',query:{id:item.id}}" class="watch-info">详情</router-link>
                <p class="watch-info" v-if="item.status === 10" @click="orderCancel(item.id)">取消</p>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-box" v-if="list.length && !loading">
        <el-pagination
           background
           @current-change="handleCurrentChange"
           :current-page="currentPage"
           layout="prev, pager, next"
           :page-size="pageSize"
           :total="count">
        </el-pagination>
      </div>
      <div class="empty" v-if="!list.length && !loading">
        <table cellspacing="0">
          <thead>
          <tr>
            <th>商品信息</th>
            <th style="width: 120px;">创业者信息</th>
            <th style="width: 120px;">合伙人信息</th>
            <th style="width: 120px;">客户信息</th>
            <th style="width: 80px;">金额</th>
            <th style="width: 80px;">操作</th>
          </tr>
          </thead>
        </table>
        <img src="../../public/images/empty.png"/>
        <p class="msg">暂无订单</p>
      </div>
      <footer-app></footer-app>
    </div>
    <!--购物车弹窗-->
    <cart-app @getPayInfo="getPayInfo"></cart-app>
    <!--支付弹窗-->
    <payApp :qr_code="qr_code" :order_no="order_no" :order_id="order_id" pay_type="1"></payApp>
    <!--分配弹窗-->
    <distributeApp :userData="userData" :reportData="reportData" :orderId="id" @success="getList"></distributeApp>
  </div>
</template>

<script>
  import payApp from '../components/payApp';
  import cartApp from '../components/cartApp';
  import distributeApp from '../components/distributeApp';
  import footerApp from '../components/footerApp';
  import {mapMutations} from 'vuex';

  export default {
    name: 'order',
    components: {
      payApp,
      distributeApp,
      cartApp,
      footerApp
    },
    data() {
      return {
        userName: '',
        cate: [],
        list: [],
        loading: true,
        qr_code: '',
        order_no: '',
        order_id: '',
        cartList: [],

        userData: [],
        reportData: [],
        id: 0,
        currentPage: 1, //初始页
        pageSize: 10,
        count: 0,
        submission: false,
      };
    },
    watch: {
      '$route.params.t'(val) {
        this.getList(val);
      },
    },
    methods: {
      ...mapMutations([
        'changeIsPayDialog',
        'changeIsDistribute',
        'changeIsCartDialog',
      ]),
      getCate() {
        this.$api.orderType().then(res => {
          this.cate = res || [];
        });
      },
      getList(val) {
        var type = val || this.$route.params.t || 0;
        var data = {
          type: type,
          nowPage: this.currentPage || 1,
          pageNumber: this.pageSize,
        };
        this.$api.orderIndex(data).then(res => {
          this.list = res.list || [];
          this.count = res.count || 0;
          this.loading = false;
        });
      },
      goPay(id) {
        this.submission = true;
        var data = {
          id:id,
          type:1
        }
        this.$api.payPay(data).then(res => {
          if (res) {
            this.qr_code = res.qr_code || '';
            this.order_no = res.order_no || '';
            this.order_id = res.id || 0;
            this.changeIsPayDialog(true);
            this.submission = false;
          }
          else {
            setTimeout(() => {
              this.submission = false;
            }, 2000);
          }
        });
      },
      orderCancel(id) {
        this.$confirm('确定取消当前订单吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$api.orderCancel(id).then(res => {
            if (res) {
              this.$message.success('取消成功');
              this.getList();
            }
          });
        });
      },
      goDistribute(id) {
        this.submission = true;
        this.id = id || 0;
        this.$api.orderUser(id).then(res => {
          if (res) {
            this.userData = res.user_data || [];
            this.reportData = res.report_data || [];
            this.changeIsDistribute(true);
            this.submission = false;
          }
          else {
            setTimeout(() => {
              this.submission = false;
            }, 2000);
          }
        });
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.getList();
      },
      getPayInfo(res) {
        this.qr_code = res.qr_code || '';
        this.order_no = res.order_no || '';
        this.order_id = res.id || 0;
        this.changeIsCartDialog(false);
        localStorage.removeItem('cartGoods');
        this.cartList = JSON.parse(localStorage.getItem('cartGoods') || '[]');
        this.changeIsPayDialog(true);
      },
    },
    created() {
      this.getCate();
      this.getList();
      this.$api.userInfo().then(res => {
        if (res) {
          this.userName = res.name || '';
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  .wrap-order {
    width: 100%;
    height: 100vh;

    .header {
      height: 80px;
      font-size: 0;
      line-height: 0;
      background: #fff;
      padding: 10px 0;
      box-sizing: border-box;

      .content {
        display: flex;
      }

      .logo {
        display: flex;
        flex: 1;

        .text {
          font-size: 20px;
          font-weight: bold;
          line-height: 60px;
          padding-left: 10px;
        }
      }

      .fun-list {
        display: flex;
        list-style: none;
        width: 220px;
        text-align: right;

        li {
          font-size: 14px;
          color: #333;
          line-height: 60px;
          padding-left: 30px;
          cursor: pointer;
          position: relative;
          margin-left: 30px;
          .icon {
            width: 28px;
            height: 28px;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -14px;
            img{
              display: block;
            }
          }
        }
      }
    }

    .content {
      width: 1200px;
      margin: 0 auto;
    }

    .order-box {
      height: 56px;
      line-height: 56px;
      display: flex;
      background: #fff;
      border-radius: 28px;
      margin: 20px 0;
      width: 580px;
      padding: 0 28px;
      box-sizing: border-box;

      .back-text {
        font-size: 14px;
        height: 58px;
        line-height: 58px;
        position: relative;
        padding-left: 34px;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          top: 50%;
          margin-top: -12px;
          left: 0;
          background: url("../assets/imgs/back-icon.svg") no-repeat center center;
          background-size: cover;
        }
      }

      .order-nav {
        flex: 1;
        font-size: 0;
        line-height: 0;
        display: inline-block;
        padding-left: 60px;

        li {
          padding-right: 40px;
          display: inline-block;
          position: relative;
          height: 58px;
          line-height: 58px;

          &:last-child {
            padding-right: 0;
          }

          a {
            display: block;
            vertical-align: middle;
            color: #333;
            font-size: 14px;
            position: relative;

            .num {
              display: inline-block;
              content: '';
              position: absolute;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: #bf1a2c;
              color: #fff;
              text-align: center;
              line-height: 14px;
              top: -5px;
              right: -10px;
              font-size: 12px;
            }

            &.active {
              color: #0A7BFF;
              font-weight: bold;

              &:after {
                position: absolute;
                content: '';
                display: inline-block;
                width: 1em;
                height: 2px;
                background: #0A7BFF;
                bottom: 0;
                left: 50%;
                margin-left: -.5em;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }

    .order-list {
      background: #fff;
      width: 100%;
    }

    table {
      width: 100%;

      th {
        height: 30px;
        font-size: 14px;
        line-height: 40px;
        min-height: 40px;
        color: #8c8c8c;
        background: #E3E6EC;
        font-weight: 400;
        box-sizing: border-box;
      }

      tbody {
        .sep-row {
          height: 10px;
          background: #F0F2F5;

          td {
            border: 0;
          }
        }

        .order-num {
          background: #F8F9FA;
          height: 35px;
          line-height: 35px;
          color: #aaa;
          overflow: hidden;

          td {
            border-bottom: 1px solid #f5f5f5;
            position: relative;

            .time {
              display: inline-block;
              color: #8c8c8c;
              padding-left: 20px;
            }

            .number {
              display: inline-block;
              padding-left: 40px;
              color: #8c8c8c;

              span {
                color: #231f20;
              }
            }

            .order-status {
              position: absolute;
              right: 20px;
              font-size: 12px;
              color: #999;
              height: 35px;
              line-height: 35px;
            }
          }
        }

        .order-detail {
          text-align: center;
          vertical-align: top;

          td {
            border: 1px solid #f0f4f7;
            vertical-align: middle;
            font-size: 14px;
            line-height: 30px;
            color: #333;

            .tag {
              font-size: 10px;
              color: #999;
              display: inline-block;
              padding-left: 5px;
            }

            .goods-item {
              display: flex;
              padding: 14px;

              .p-img {
                width: 88px;
                height: 88px;
                border: 1px solid #efefef;

                img {
                  width: 100%;
                  height: 100%;
                  border: 0;
                  vertical-align: middle;
                }
              }

              .p-msg {
                font-size: 14px;
                flex: 1;
                padding-left: 20px;

                .p-name {
                  overflow: hidden;
                  color: #666666;
                  text-align: left;
                }

                .p-area {
                  color: #aaa;
                  text-align: left;
                }

                .goods-number {
                  color: #666;
                }
              }
            }

            .name {
              font-size: 12px;
              color: #595959;
              position: relative;

              .icon {
                display: inline-block;
                width: 18px;
                height: 18px;
              }
            }

            .price {
              font-size: 12px;
              color: #b1b1b1;
            }


            .opearte {
              line-height: 22px;

              .watch-info {
                display: block;
                padding-top: 10px;
                cursor: pointer;
              }

              .confirm-btn {
                padding: 5px 10px;
                background: rgba(10, 123, 255, 0.1);
                color: #0A7BFF;
                border-radius: 2px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
                border: 0;
                font-size: 12px;
                outline: 0;
                cursor: pointer;
              }

              .del {
                font-size: 12px;
                color: #231f20;
                padding-top: 10px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .pagination-box {
      text-align: right;
      padding: 20px 0;
      ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color:#4E87F6; //修改后的背景图颜色
        color: #fff;
      }
    }

    .empty {
      background: #fff;
      height: 720px;

      img {
        width: 250px;
        height: 162px;
        display: block;
        margin: 20% auto 0;
      }

      .msg {
        text-align: center;
        font-size: 14px;
        color: #ccc;
        padding: 20px 0;
      }
    }

  }
</style>
