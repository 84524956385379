import axios from 'axios';
import qs from 'qs';
import router from './router';
import localStorage from 'localStorage';
import {Message} from 'element-ui';

var CONF = {
  // API_URL: 'http://xf.86v5.com/seller/cashier.',
  API_URL: 'https://api.mtliss.com/v7/',
};
axios.interceptors.request.use(config => {
      return config;
    },
    err => {
      return Promise.reject(err);
    },
);

axios.interceptors.response.use(response => {
  return response;
}, err => {
  if (err.response) {
    switch (err.response.status) {
      case 301:
        let json = err.response.data;
        Message({
          message: json.msg,
          type: 'error',
        });
        break;
      case 401:
        Message({
          message: '登陆超时 请重新登陆',
          type: 'error',
          onClose: () => {
            router.replace({name: 'Login'});
          },
        });
        break;
      case 404:
        break;
      default:
        break;
    }
  }
  return Promise.reject(err.response);
});

const fetch = async (url = '', params = {}, method = 'post') => {
  if (localStorage.getItem('token')) {
    params.token = localStorage.getItem('token');
  }
  return axios({
    method: 'post',
    url: `${CONF.API_URL}${url}`,
    data: qs.stringify(params),
  }).then(response => response.data).then((json) => {
    if (json.code === 200) {
      return json.data;
    }
    else {
      Message({
        message: json.msg || '登陆超时 请重新登陆',
        type: 'error',
      });
      return false;
    }
  });
};

//登陆
const userLogin = (tel, password) => {
  return fetch('user/login', {tel, password});
};
//退出登陆
const userOut = () => {
  return fetch('user/out');
};
//商品列表
const goodsIndex = () => {
  return fetch('goods/index');
};
//商品规格
const goodsSku = (id) => {
  return fetch('goods/sku', {id});
};
//提交订单
const paySubmit = (data) => {
  return fetch('order/submit', data);
};
//检测是否支付
const payStatus = (data) => {
  return fetch('pay/status', data);
};
//订单头部
const orderType = () => {
  return fetch('order/type');
};
//订单列表
const orderIndex = (data) => {
  return fetch('order/index ', data);
};
//订单详情
const orderInfo = (id) => {
  return fetch('order/info ', {id});
};
//订单取消
const orderCancel = (id) => {
  return fetch('order/cancel', {id});
};
//订单支付
const payPay = (data) => {
  return fetch('pay/pay', data);
};
//获取合伙人
const orderUser = (id) => {
  return fetch('order/user', {id});
};
//订单分配
const orderDistribution = (data) => {
  return fetch('order/distribution', data);
};
//获取手机号
const userInfo = () => {
  return fetch('user/info')
}
//获取验证码
const userCode = (tel,type) => {
  return fetch('user/code',{tel,type})
}
//重置密码提交
const userEdit = (data) => {
  return fetch('user/edit',data)
}


//收取定金
const depositCollect = (data) => {
  return fetch('deposit/collect',data)
}
//定金列表头部
const depositHead = () => {
  return fetch('deposit/head')
}
//定金列表
const depositIndex = (data) => {
  return fetch('deposit/index',data)
}
//购物车定金返回
const orderDeposit = (tel) => {
  return fetch('order/deposit',{tel})
}
//分配人员返回
const depositUser = (id) => {
  return fetch('deposit/user',{id})
}
//定金分配提交
const depositDistribution = (data) => {
  return fetch('deposit/distribution',data)
}
//抛出数据
export default {
  userLogin,
  userOut,
  goodsIndex,
  goodsSku,
  paySubmit,
  payStatus,
  orderType,
  orderIndex,
  orderInfo,
  orderCancel,
  payPay,
  orderUser,
  orderDistribution,
  userInfo,
  userCode,
  userEdit,

  depositCollect,
  depositHead,
  depositIndex,
  orderDeposit,
  depositUser,
  depositDistribution
};
