<template>
  <div class="wrap-order-info">
    <div class="position"><router-link :to="{path:'/index'}">首页</router-link> > <router-link :to="{path:'/order/0'}">订单列表</router-link> > 订单详情</div>
    <div class="order-state">
      <div class="state-cont">
        <div class="state-lcol">
          <div class="state-top">订单编号：{{number}}<span class="text">下单人员：{{cashier_name}}</span></div>
          <div class="state-top">下单时间：{{create_at}}</div>
          <div class="state-txt">{{status_name}}</div>
          <el-button class="pay" :loading="btnLoading" :disabled="submission?true:false" @click="goPay" v-if="status === 20">立即付款</el-button>
          <el-button class="pay" :loading="btnLoading" :disabled="submission?true:false" v-if="status === 25" @click="goDistribute">立即分配</el-button>
        </div>
        <div class="state-rcol">
          <div class="wait-pay">{{status_name}}</div>
          <ul class="progress">
            <li v-for="(item,i) in process"
                :class="[{'active':item.status<status},{'current':item.status === status}]">
              <i class="icon" :class="item.icon"></i>
              <p class="txt">{{item.name}}</p>
              <p class="time">{{item.date}}<br/>{{item.time}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="order-state" style="border-top: 0">
      <div class="state-cont">
        <div class="state-lcol">
          <dl class="partner">
            <dt>合伙人信息</dt>
            <dd>
              <div class="item">
                <span class="name">合伙人：</span>
                <p>{{partner.name}}</p>
              </div>
              <div class="item">
                <span class="name">电&emsp;话：</span>
                <p>{{partner.tel}}</p>
              </div>
            </dd>
          </dl>
          <dl class="owner">
            <dt>创业者信息</dt>
            <dd>
              <div class="item">
                <span class="name">创业者：</span>
                <p>{{pioneer.name}}</p>
              </div>
              <div class="item">
                <span class="name">电&emsp;话：</span>
                <p>{{pioneer.tel}}</p>
              </div>
            </dd>
          </dl>

          <dl class="consignee">
            <dt>客户信息</dt>
            <dd>
              <div class="item">
                <span class="name">姓&emsp;名：</span>
                <p>{{user.name}}</p>
              </div>
              <div class="item">
                <span class="name">电&emsp;话：</span>
                <p>{{user.tel}}</p>
              </div>
              <div class="item">
                <span class="name">地&emsp;址：</span>
                <p>{{user.address}}</p>
              </div>
            </dd>
          </dl>
        </div>
        <div class="state-rcol">
          <ul class="status-item log">
            <li class="log-item" v-for="item in statuslog">
              <i class="line"></i>
              <span class="time">{{item.create_at}}</span>
              <span class="number">订单号：{{item.number}}</span>
              <span class="status">{{item.title}} 【{{item.user_name}}】</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <table class="order-box">
      <thead class="order-title">
      <th colspan="2">商品信息</th>
      <th style="width: 140px">商品编号</th>
      <th style="width: 160px">单价</th>
      <th style="width: 160px">数量</th>
      <th style="width: 120px">金额</th>
      </thead>
      <tbody style="border: 0;">
      <tr v-for="(item,i) in goodsList">
        <td width="70px">
          <div class="cover">
            <img :src="item.cover_img">
          </div>
        </td>
        <td>
          <p class="title">{{item.goods_name}}</p>
          <p class="info">{{item.number}}</p>
        </td>
        <td class="model">{{item.number}}</td>
        <td class="price">
          <p>￥{{item.price}}</p>
        </td>
        <td>
          <p style="display: inline-block">x{{item.num}}</p>
        </td>
        <td class="price center">
          <p>￥{{item.total_price}}</p>
        </td>
      </tr>
      </tbody>
      <tbody>
      <tr>
        <td colspan="6">
          <!--保存订单-->
          <div class="pay-total">
            <div class="total">
              <span v-if="deposit_price">支付定金：￥{{deposit_price}}</span>
              商品总额：￥{{total_fee}}
              <input type="button" :disabled="submission?true:false" class="sub-order" value="付款" v-if="status === 20" @click="goPay"/>
              <input type="button" :disabled="submission?true:false" class="sub-order" value="分配" v-if="status === 25" @click="goDistribute"/>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <!--支付弹窗-->
    <payApp :qr_code="qr_code" :order_no="order_no" :order_id="order_id"></payApp>
    <!--分配弹窗-->
    <distributeApp :userData="userData" :reportData="reportData" :orderId="id" @success="getInfo"></distributeApp>
  </div>
</template>

<script>
  import {mapMutations} from 'vuex';
  import payApp from '../components/payApp';
  import distributeApp from '../components/distributeApp';

  export default {
    name: 'OrderInnfo',
    components: {
      payApp,
      distributeApp,
    },
    data() {
      return {
        id: 0,
        status: 0,
        status_name: '',
        number: '',
        create_at: '',
        cashier_name: '',
        process: [],
        statuslog: [],
        goodsList: [],
        user: {},
        partner: {},
        pioneer: {},
        total_fee: '',
        deposit_price:'',

        qr_code: '',
        order_no: '',
        order_id: '',

        userData: [],
        reportData: [],

        btnLoading:false,
        submission: false,
      };
    },
    methods: {
      ...mapMutations([
        'changeIsPayDialog',
        'changeIsDistribute',
      ]),
      goPay() {
        this.btnLoading = true
        this.submission = true;
        var data = {
          id:this.id,
          type:1
        }
        this.$api.payPay(data).then(res => {
          if(res){
            this.qr_code = res.qr_code || '';
            this.order_no = res.order_no || '';
            this.order_id = res.id || 0;
            this.changeIsPayDialog(true);
            this.btnLoading = false
            this.submission = false;
          }else{
            setTimeout(()=>{
              this.btnLoading = false
              this.submission = false;
            },2000)
          }
        });
      },
      goDistribute() {
        this.$api.orderUser(this.id).then(res => {
          if(res){
            this.userData = res.user_data || [];
            this.reportData = res.report_data || [];
            this.changeIsDistribute(true)
            this.btnLoading = false
            this.submission = false;
          }else{
            setTimeout(()=>{
              this.btnLoading = false
              this.submission = false;
            },2000)
          }
        });
      },
      getInfo(){
        this.$api.orderInfo(this.id).then(res => {
          this.process = res.process || [];
          this.statuslog = res.log || [];
          this.goodsList = res.order.order_list || [];
          this.user = res.order.user_info || {};
          this.partner = res.order.partner || {};
          this.pioneer = res.order.pioneer || {};
          this.number = res.order.number || '';
          this.create_at = res.order.create_at || '';
          this.total_fee = res.order.total_fee || '';
          this.deposit_price = res.order.deposit_price || ''
          this.cashier_name = res.order.cashier_name || '';
          this.status = res.order.next_status || 0;
          this.status_name = res.order.status_name || '';
        });
      }
    },
    created() {
      this.id = this.$route.query.id || 0;
      this.getInfo()
    },
  };
</script>

<style lang="scss" scoped>
  .wrap-order-info {
    width: 1200px;
    display: block;
    margin: 0 auto;

    .position {
      height: 52px;
      line-height: 52px;
      font-size: 12px;
      color: #231f20;
    }

    .order-state {
      border-top: 3px solid #0A7BFF;
      margin-bottom: 25px;

      .state-cont {
        background: #fff;
        border: 1px solid #eee;
        display: flex;

        .state-lcol {
          width: 325px;
          padding: 14px;
          text-align: center;
          display: inline-block;

          .state-top {
            font-size: 12px;
            color: #999999;
            text-align: left;

            .text {
              float: right;
            }
          }

          .state-txt {
            font-size: 24px;
            color: #0A7BFF;
            margin-top: 60px;
            margin-bottom: 10px;
          }

          .time {
            font-size: 12px;
            color: #8c8c8c;
            margin-bottom: 30px;
          }

          .pay {
            width: 96px;
            height: 28px;
            line-height: 28px;
            background: #0A7BFF;
            text-align: center;
            color: #fff;
            display: block;
            margin: 20px auto 50px;
            cursor: pointer;
            border-radius: 3px;
            padding: 0;
            border: 0;
            outline: 0;
          }

          dl {
            text-align: left;
            padding: 10px 15px;
            color: #1f1f1f;
            margin-bottom: 14px;

            &:last-child {
              margin-bottom: 0;
            }

            &.consignee {
              background: #fff7f3;
            }

            &.owner {
              background: rgba(10, 123, 255, .05);
            }

            &.partner {
              background: rgba(255, 45, 182, .05);
              margin-top: 0;
            }

            dt {
              font-size: 14px;
              color: #333;
            }

            dd {
              font-size: 14px;
              color: #333;
              padding: 10px 0 0 0;
              margin: 0;

              .name {
                width: 4em;
                display: inline-block;
                vertical-align: top;
              }

              p {
                display: inline-block;
                width: 235px;
              }
            }
          }
        }

        .state-rcol {
          flex: 1;
          border-left: 1px solid #f1f1f1;
          padding: 20px 20px 38px 20px;
          display: inline-block;

          .wait-pay {
            font-size: 16px;
            color: #0A7BFF;
            padding: 0 0 30px 0;
            text-align: center;
          }

          .status-item {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
            line-height: 24px;
            height: 100%;
            overflow: auto;

            .title {
              font-size: 14px;
              color: #282828;
              font-weight: bold;
            }

            .log-none {
              padding: 20px;
              text-align: center;
              color: #ccc;
            }

            &.log {
              width: 600px;

              .log-item {
                position: relative;
                padding-left: 35px;

                &:nth-child(1) {
                  font-weight: bold;

                  &:before {
                    background: rgba(10, 123, 255, 1);
                  }

                  &:after {
                    background: rgba(10, 123, 255, .5);
                  }
                }

                &:last-child {
                  .line {
                    display: none;
                  }
                }

                .line {
                  width: 1px;
                  height: 100%;
                  background: #ccc;
                  left: 11px;
                  top: 8px;
                  z-index: 1;
                }

                &:before, &:after, .line {
                  position: absolute;
                  content: ' ';
                  display: block;
                }

                &:before {
                  width: 5px;
                  height: 5px;
                  background: #ccc;
                  border-radius: 50%;
                  left: 9px;
                  top: 9px;
                  z-index: 3;
                }

                &:after {
                  width: 9px;
                  height: 9px;
                  border-radius: 50%;
                  background: #f7f7f7;
                  left: 7px;
                  top: 7px;
                  z-index: 2;
                }

                span {
                  display: inline-block;

                  &.time {
                    min-width: 130px;
                  }

                  &.number {
                    min-width: 210px;
                  }

                  &.name {
                    min-width: 110px;
                  }

                  &.status {
                    min-width: 90px;
                  }
                }
              }
            }
          }

          .progress {
            font-size: 0;
            line-height: 0;
            display: flex;
            text-align: center;

            li {
              flex: 1;
              position: relative;
              text-align: center;

              &:last-child {
                &:after {
                  display: none;
                  padding-right: 0;
                }
              }

              &.active {
                .icon {
                  &.icon-1 {
                    background: url("../assets/imgs/progress-active-01.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-2 {
                    background: url("../assets/imgs/progress-active-02.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-3 {
                    background: url("../assets/imgs/progress-active-03.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-4 {
                    background: url("../assets/imgs/progress-active-04.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-5 {
                    background: url("../assets/imgs/progress-active-05.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-6 {
                    background: url("../assets/imgs/progress-06.png") no-repeat center center;
                    background-size: 50% 50%;
                  }
                }
              }

              &.current {
                .icon {
                  &.icon-1 {
                    background: url("../assets/imgs/progress-current-01.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-2 {
                    background: url("../assets/imgs/progress-current-02.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-3 {
                    background: url("../assets/imgs/progress-current-03.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-4 {
                    background: url("../assets/imgs/progress-current-04.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-5 {
                    background: url("../assets/imgs/progress-current-05.png") no-repeat center center;
                    background-size: 50% 50%;
                  }

                  &.icon-6 {
                    background: url("../assets/imgs/progress-current-06.png") no-repeat center center;
                    background-size: 50% 50%;
                  }
                }
              }

              .icon {
                display: inline-block;
                width: 70px;
                height: 70px;

                &.icon-1 {
                  background: url("../assets/imgs/progress-01.png") no-repeat center center;
                  background-size: 50% 50%;
                }

                &.icon-2 {
                  background: url("../assets/imgs/progress-02.png") no-repeat center center;
                  background-size: 50% 50%;
                }

                &.icon-3 {
                  background: url("../assets/imgs/progress-03.png") no-repeat center center;
                  background-size: 50% 50%;
                }

                &.icon-4 {
                  background: url("../assets/imgs/progress-04.png") no-repeat center center;
                  background-size: 50% 50%;
                }

                &.icon-5 {
                  background: url("../assets/imgs/progress-05.png") no-repeat center center;
                  background-size: 50% 50%;
                }

                &.icon-6 {
                  background: url("../assets/imgs/progress-06.png") no-repeat center center;
                  background-size: 50% 50%;
                }
              }

              p {
                font-size: 12px;
                color: #231f20;
                line-height: 14px;

                &.txt {
                  font-size: 14px;
                  color: #333333;
                  padding-bottom: 10px;
                }
              }
              .time{
                color: #999999;
                font-size: 12px;
              }

              &:after {
                position: absolute;
                content: '';
                display: inline-block;
                top: 30%;
                left: 60%;
                width: 80%;
                height: 0;
                opacity: 0.4;
                border-bottom: 1px dashed #0A7BFF;
              }
            }
          }
        }
      }
    }

    .order-box {
      width: 100%;
      border: 1px solid #eee;
      margin-top: 25px;

      tbody {
        background: #fff;
      }

      th {
        height: 40px;
        text-align: left;
        padding: 0 14px;
        font-size: 12px;
        line-height: 20px;
        min-height: 40px;
        color: #333;
        background: #E3E6EC;
        font-weight: 400;
        box-sizing: border-box;

        &:last-child {
          padding: 0;
          text-align: center;
        }
      }

      td {
        padding: 20px;
        color: #333333;
        font-size: 14px;
        border-bottom: 1px solid #e6e6e6;

        &.bag-rowspan {
          vertical-align: top;
        }

        .cover {
          width: 68px;
          height: 68px;
          display: inline-block;
          background: #F0F0F0;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          font-size: 14px;
          padding-bottom: 15px;
          color: #333333;
        }

        .info {
          color: #999999;
          font-size: 14px;
        }

        .del-edit {
          text-align: center;
          color: #595959;
          display: block;
          cursor: pointer;
          height: 20px;
          line-height: 20px;
        }

        .count {
          overflow: hidden;
          font-size: 0;
          line-height: 0;
          vertical-align: middle;
          display: inline-block;
          border: 1px solid #aba7a7;

          li {
            display: inline-block;
            vertical-align: top;

            span {
              display: inline-block;
              width: 22px;
              height: 22px;
              line-height: 22px;
              text-align: center;
              font-size: 12px;
              color: #aba7a7;
              cursor: pointer;

              &.input-num {
                border-left: 1px solid #aba7a7;
                border-right: 1px solid #aba7a7;
              }
            }
          }
        }
      }

      .center {
        text-align: center;
      }
    }

    .pay-total {
      box-sizing: border-box;
      background: #fff;
      float: right;
      font-size: 12px;
      color: #231f20;
      span{
        padding-right: 20px;
        color: #666666;
      }

      .total {
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }

      .sub-order {
        font-size: 12px;
        color: #bf1a2c;
        background: rgba(10, 123, 255, 0.1);
        color: rgba(10, 123, 255, 1);
        padding: 5px 10px;
        margin-left: 10px;
        cursor: pointer;
        border-radius: 3px;
      }
    }

  }
</style>
