<template>
  <el-drawer
     title="选择分配人"
     :visible.sync="isDepositDistribute"
     :size="500"
     :before-close="handleClose"
     direction="rtl">
    <div slot="title" class="header-title">
      <span class="title-name">选择分配人</span>
      <span class="title-icon"></span>
    </div>
    <div class="distribute-box">
      <div class="distribute-main">
        <div class="tree-box">
          <el-tree
             :data="userData"
             show-checkbox
             node-key="id"
             ref="tree"
             check-strictly
             @check-change="handleClick"
             :props="defaultProps">
          </el-tree>
        </div>
      </div>
      <div class="distribute-footer">
        <el-button type="primary" @click="submit" style="background-color: #0A7BFF">确 定</el-button>
        <el-button @click="changeIsDepositDistribute(false)">取 消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    name: 'depositDistributeDialog',
    props: {
      userData: [],
      orderId: 0,
    },
    computed: {
      ...mapState({
        isDepositDistribute: state => state.isDepositDistribute,
      }),
    },
    data() {
      return {
        defaultProps: {
          children: 'partner',
          label(data, node) {
            return data.name + '-' + data.tel;
          },
        },
        report_id: 0,
        is_pioneer: 0,
        user_id: 0,
      };
    },
    methods: {
      ...mapMutations([
        'changeIsDepositDistribute',
      ]),
      handleClick(data, checked, node) {
        if (checked) {
          this.report_id = data.report_id || 0;
          this.is_pioneer = data.is_pioneer || 0;
          this.user_id = data.id || 0;
          this.$refs.tree.setCheckedKeys([data.id]);
        }
      },
      handleClose() {
        this.changeIsDepositDistribute(false);
      },
      submit() {
        var data = {
          id: this.orderId,
          is_pioneer: this.is_pioneer ,
          select_user_id: this.user_id
        };
        this.$api.depositDistribution(data).then(res => {
          if (res) {
            this.$message.success('分配成功');
            this.handleClose();
            this.$emit('success');
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header-title{
    position: relative;
    .title-name{
      font-size: 16px;
      color: #333333;
    }
    .title-icon{
      width: 16px;
      height: 16px;
      background: url("../assets/imgs/icon-order.png") no-repeat center center;
      background-size: cover;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      left: 7.5em;
    }
  }
  .distribute-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .distribute-footer {
      height: 60px;
      line-height: 60px;
      border-top: 1px solid #eee;
      text-align: center;
    }

    .distribute-main {
      display: flex;
      padding: 0 20px;
      flex: 1;
      overflow-y: auto;

      .tree-box {
        width: 45%;
      }

      .user-list {
        flex: 1;
        padding: 0 20px;
        font-size: 0;
        line-height: 0;

        li {
          padding: 0 0 20px 34px;
          box-sizing: border-box;
          display: block;
          margin-bottom: 12px;
          border: 1px solid #EEEEEE;
          border-radius: 3px;
          position: relative;

          .choose-icon {
            width: 14px;
            height: 14px;
            border: 1px solid #D5D8DE;
            border-radius: 3px;
            position: absolute;
            top: 12px;
            left: 10px;

            &.checked {
              border: 1px solid #0A7BFF;
              background: url("../assets/imgs/check-icon.png") no-repeat center center;
              background-size: cover;
            }
          }

          .name {
            color: #333;
            font-size: 16px;
            height: 20px;
            line-height: 20px;
            padding: 10px 0 12px 0;
          }

          .user-name {
            color: #666;
            font-size: 14px;
            height: 17px;
            line-height: 17px;
            padding-bottom: 5px;
          }

          .tag {
            width: 44px;
            height: 13px;
            position: absolute;
            right: 10px;
            bottom: 10px;

            &.partner {
              background: url("../assets/imgs/partner_bg.png") no-repeat center center;
              background-size: cover;
            }

            &.chuang {
              background: url("../assets/imgs/chuang_bg.png") no-repeat center center;
              background-size: cover;
            }
          }
        }
      }
    }
  }

</style>
