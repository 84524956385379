<template>
  <div class="footer">
    <div class="content">
      <div class="top-con">
        <ul class="list">
          <li>
            <p class="name">关于我们</p>
            <a class="text" target="_blank" href="https://www.mtliss.com/about/index.html">了解蒙特利斯</a>
            <a class="text" target="_blank" href="https://www.mtliss.com/about/team.html">设计团队</a>
            <a class="text" target="_blank" href="https://www.mtliss.com/about/culture.html">企业文化</a>
            <a class="text" target="_blank" href="https://www.mtliss.com/about/history.html">发展历程</a>
          </li>
          <li>
            <p class="name">服务支持</p>
            <a class="text" target="_blank" href="https://www.mtliss.com/service/guarantee.html">服务保障</a>
            <a class="text" target="_blank" href="https://www.mtliss.com/service/agreement.html">用户协议</a>
            <a class="text" target="_blank" href="https://www.mtliss.com/service/contact.html">联系我们</a>
          </li>
          <li>
            <p class="name">商业合作</p>
            <a class="text" target="_blank" href="https://www.mtliss.com/about/investment.html">招商加盟</a>
          </li>
        </ul>
        <div class="contact-us">
          <p class="text">联系电话：</p>
          <p class="tel">400 619 1431</p>
          <p class="text">工作日 9:00～18:00</p>
        </div>
      </div>
      <div class="bottom-con">
        <p>Copyright © 2019 MTLiss.com Inc.All Rights Reserved. 北京蒙特利斯科技有限公司版权所有 蒙特利斯官网 整体厨房 + 整体卫浴 + 全屋整装 家居品牌</p>
        <p class="icon">京ICP备2021007073号 京公网安备11011202002665号</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'footerApp',
  };
</script>

<style lang="scss" scoped>
  .footer{
    margin-top: 40px;
    .content{
      width: 1200px;
      margin: 0 auto;
    }
    .top-con{
      display: flex;
      background: #edeff0 url("../assets/imgs/footer_bg.png") no-repeat bottom center;
      background-size: contain;
      border-radius: 12px;
      padding: 10px 0 10px 100px;
      ul{
        display: flex;
        flex: 1;
        list-style: none;
        font-size: 0;
        line-height: 0;
        li{
          flex: 1;
          .name{
            font-size: 14px;
            color: #333333;
            padding: 20px 0;
            font-weight: bold;
          }
          .text{
            font-size: 12px;
            color: #666;
            line-height: 30px;
            cursor: pointer;
            display: block;
          }
        }
      }
      .contact-us{
        width: 250px;
        padding: 42px 60px;
        box-sizing: border-box;
        position: relative;
        &:after{
          content: '';
          display: block;
          width: 1px;
          height: 100px;
          position: absolute;
          top: 50%;
          margin-top: -50px;
          left: 0;
          background: rgba(51, 51, 51, 0.2);
        }
        .text{
          color: #666666;
          font-size: 12px;
        }
        .tel{
          color: #333;
          font-size: 20px;
          padding: 12px 0;
          font-weight: bold;
        }
      }
    }
    .bottom-con{
      padding-top: 14px;
      p{
        text-align: center;
        font-size: 12px;
        color: #999;
        line-height: 24px;
      }
      .icon{
        position: relative;
        &:after{
          content: '';
          display: block;
          width: 12px;
          height: 13px;
          position: absolute;
          left: 36%;
          top: 50%;
          margin-top: -6px;
          background: url("../assets/imgs/icon.png") no-repeat center center;
          background-size: cover;
        }
      }
    }
  }
</style>
