<template>
  <div class="wrap wrap-login">
    <div class="login-body">
      <div class="login-left"></div>
      <div class="login-right">
        <div class="login-header">
          <p class="login-subtitle">欢迎使用</p>
          <p class="login-title">管理平台</p>
        </div>
        <div class="login-content">
          <el-form ref="form"
                   size="medium"
                   label-width="0">
            <el-form-item>
              <el-input prefix-icon="el-icon-user" v-model="tel" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input prefix-icon="el-icon-mobile" type="password" v-model="password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-button type="primary" :loading="btnLoading" :disabled="submission?true:false" style="width: 100%;" @click.stop="login">
              {{submission?'登录中...':'登录'}}
            </el-button>
          </el-form>
          <span class="find-pass" @click="passwordDialog = true">找回密码</span>
        </div>
      </div>
    </div>
    <!--修改密码弹窗-->
    <el-dialog
       title="找回密码"
       :visible.sync="passwordDialog"
       width="22%"
       :before-close="handleClose">
      <el-form ref="form">
        <el-form-item>
          <el-input placeholder="手机号" v-model="telphone" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="验证码" v-model="code" clearable></el-input>
          <span class="get-code" :class="{'disabled': codeTimer}" @click.stop="sendCode">{{codeText}}</span>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入新密码" v-model="eeditPassword" show-password clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%" @click="passwordSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tel: '',
        password: '',
        btnLoading:false,
        submission: false,

        isShow:false,
        passwordDialog:false,
        telphone:'',
        eeditPassword:'',
        code:'',
        codeText: '获取验证码',
        codeTimer: 0,
        timer: null,
        sending: false
      };
    },
    watch: {
      codeTimer (val) {
        if(val > 0) {
          let s = val > 9 ? val : `0${val}`;
          this.codeText = `${s}秒后重发`
        }else{
          this.codeText = '重新获取'
        }
      }
    },
    methods: {
      handleClose(done) {
        done();
      },
      login(){
        if (!/^1[3456789]\d{9}$/.test(this.tel)) {
          this.$message.error('手机号码有误');
          return;
        }
        if(this.password === ''){
          this.$message.error('密码不能为空');
          return;
        }
        this.btnLoading = true
        this.submission = true;
        this.$api.userLogin(this.tel,this.password).then(res => {
          if(res){
            let info = JSON.stringify(res.info)
            localStorage.setItem('info',info || {})
            localStorage.setItem('token', res.token || '');
            this.$message({
              message: '登录成功',
              type: 'success',
              onClose: () => {
                this.$router.push({
                  path: '/index',
                });
              },
            });
          }else{
            setTimeout(()=>{
              this.btnLoading = false
              this.submission = false;
            },2000)
          }
        })
      },
      intVal() {
        setTimeout(() => {
          this.codeTimer --
          if(this.codeTimer > 0) {
            this.intVal()
          }
        }, 1000);
      },
      checkTel() {
        return /^1[3456789]\d{9}$/.test(this.telphone);
      },
      sendCode () {
        if(this.codeTimer) {
          return
        }

        if(!this.checkTel()) {
          this.$message.error('手机号有误')
          return
        }

        if(this.sending) {
          return
        }

        this.sending = true

        this.$api.userCode(this.telphone,3).then(res => {
          if(res) {
            this.codeTimer = 60
            this.intVal()
          }
          this.sending = false
        })
      },
      passwordSubmit(){
        var data = {
          password:this.eeditPassword,
          tel:this.telphone,
          code:this.code
        }
        this.$api.userEdit(data).then(res => {
          if(res){
            this.$message.success('密码设置成功')
            setInterval(() => {
              this.$router.go(0);
            },2000)
          }
        })
      }
    },
    created() {

    }
  };
</script>

<style lang="scss" scoped>
  .wrap-login{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: url("../assets/imgs/login-background.png") no-repeat center center;
    background-size: cover;
    user-select: none;
    .login-body{
      width: 860px;
      background: #fff;
      position: absolute;
      min-height: 480px;
      top: 50%;
      left: 50%;
      box-sizing: border-box;
      transform: translate(-50%, -50%);
      display: flex;
      .login-left{
        width: 440px;
        background: #409EFF url("../assets/imgs/login-left.png") no-repeat center center;
        background-size: cover;
      }
      .login-right{
        flex: 1;
        padding: 50px 40px;
        position: relative;
      }
      .login-header {
        position: relative;
      }
      .el-form-item{
        display: block;
        position: relative;
        padding-bottom: 30px;
        margin: 0;
      }
      .el-button{
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0;
        margin: 20px 0 0 0;
        border: 0;
        border-radius: 0;
        color: #fff;
        background: #2e79f6;
        font-size: 16px;
        outline: none;
      }
      .el-input__inner{
        height: 40px !important;
        line-height: 30px !important;
        padding: 4px 8px 4px 41px;
        position: relative;
        z-index: 0;
        box-sizing: border-box;
        width: 100%;
        border-radius: 0;
        background: #fcfdff;
        border: 1px solid #dbe4f2;
        outline: none;
        font-size: 14px;
        font-weight: 400;
      }
      .login-subtitle {
        user-select: none;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        position: relative;
      }
      .login-title{
        user-select: none;
        font-size: 24px;
        margin-bottom: 44px;
        line-height: 1.8;
        padding-top: 10px;
      }
      .find-pass{
        font-size: 14px;
        padding-top: 10px;
        color: #2e79f6;
        cursor: pointer;
      }
    }
    .get-code{
      position: absolute;
      right: 10px;
      font-size: 12px;
      color: #409EFF;
      &.disabled {
        color: #aaa;
      }
    }
  }
</style>
