<template>
  <div class="wrap-order">
    <div class="header">
      <div class="content">
        <router-link to="/index" class="logo">
          <img src="../../public/images/logo_02.svg">
          <span class="text">收银</span>
        </router-link>
        <ul class="fun-list">
          <li @click="changeIsCartDialog(true)">
            <span class="icon"><img src="../../public/images/cart-icon.svg"></span>
            购物车
          </li>
          <li>
            <span class="icon"><img src="../../public/images/user-icon.svg"></span>
            {{userName}}
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <div class="order-nav-box">
        <div class="order-box">
          <router-link :to="{path:'/index'}" class="back-text">返回首页</router-link>
          <ul class="order-nav">
            <li v-for="(item,i) in cate">
              <router-link :to="{path:'/deposit/'+item.status}">
                {{item.name}}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="deposit-btn" @click.stop="changeIsDepositDialog(true)">收取定金</div>
      </div>
      <div class="order-list" v-if="list.length && !loading">
        <table cellspacing="0">
          <thead>
          <tr>
            <th>客户姓名</th>
            <th>电话</th>
            <th>地址</th>
            <th>定金</th>
            <th>分配</th>
            <th>状态</th>
          </tr>
          </thead>
          <tbody v-for="item in list">
          <tr class="sep-row">
            <td colspan="6"></td>
          </tr>
          <tr class="order-num">
            <td colspan="6">
              <p class="time">{{item.create_at}}</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              订单号：{{item.number}}
              <span class="order-status">{{item.status_name}}</span>
            </td>
          </tr>
          <tr class="order-detail">
            <td>
              <p>客户姓名</p>
              <p>{{item.name}}</p>
            </td>
            <td>
              <p>电话</p>
              <p>{{item.tel}}</p>
            </td>
            <td>
              <p>地址</p>
              <p>{{item.district}}{{item.region}}{{item.floor}}-{{item.unit}}-{{item.room}}</p>
            </td>
            <td>
              <p>已付款</p>
              <p>¥ {{item.price}}</p>
            </td>
            <td>
              <div class="opearte">
                <span  v-if="item.status !== 0 && item.is_distribution">{{item.distribution_name}}</span>
                <span  v-if="item.status === 0 && !item.is_distribution">未分配</span>
                <el-button class="confirm-btn" @click="depositDistribute(item)" v-if="item.status !== 0 && !item.is_distribution">分配</el-button>
              </div>
            </td>
            <td>
              <div class="opearte">
                <el-button class="confirm-btn" :disabled="paySubmission?true:false" @click="goPay(item.id)"
                           v-if="item.status === 0">支付
                </el-button>
                <el-button class="confirm-btn" v-if="item.status === 1">未使用</el-button>
                <el-button disabled v-if="item.status === 3">已使用</el-button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-box" v-if="list.length && !loading">
        <el-pagination
           background
           @current-change="handleCurrentChange"
           :current-page="currentPage"
           layout="prev, pager, next"
           :page-size="pageSize"
           :total="count">
        </el-pagination>
      </div>
      <div class="empty" v-if="!list.length && !loading">
        <table cellspacing="0">
          <thead>
          <tr>
            <th>客户姓名</th>
            <th>电话</th>
            <th>地址</th>
            <th>定金</th>
            <th>分配</th>
            <th>状态</th>
          </tr>
          </thead>
        </table>
        <img src="../../public/images/empty.png"/>
        <p class="msg">暂无定金</p>
      </div>
      <footer-app></footer-app>
    </div>
    <!--定金弹窗-->
    <deposit-app @getPayInfo="getPayInfo"></deposit-app>
    <!--购物车弹窗-->
    <cart-app @getPayInfo="getPayInfo"></cart-app>
    <!--支付弹窗-->
    <payApp :qr_code="qr_code" :order_no="order_no" :order_id="order_id" :pay_type="pay_type"
            @getList="getList"></payApp>
    <!--分配弹窗-->
    <deposit-distribute :userData="userData" :orderId="id" @success="getList"></deposit-distribute>
  </div>
</template>

<script>
  import depositApp from '../components/depositApp';
  import depositDistribute from '../components/depositDistribute';
  import footerApp from '../components/footerApp';
  import cartApp from '../components/cartApp';
  import payApp from '../components/payApp';
  import {mapMutations} from 'vuex';

  export default {
    name: 'order',
    components: {
      depositApp,
      footerApp,
      cartApp,
      payApp,
      depositDistribute,
    },
    data() {
      return {
        userName: '',
        cate: [],
        list: [],
        loading: true,
        qr_code: '',
        order_no: '',
        order_id: '',
        pay_type: 1,
        cartList: [],

        userData: [],
        id: 0,
        currentPage: 1, //初始页
        pageSize: 10,
        count: 0,
        submission: false,

        paySubmission: false,
      };
    },
    watch: {
      '$route.params.t'(val) {
        this.getList(val);
      },
    },
    methods: {
      ...mapMutations([
        'changeIsDepositDialog',
        'changeIsCartDialog',
        'changeIsPayDialog',
        'changeIsDepositDistribute',
      ]),
      getCate() {
        this.$api.depositHead().then(res => {
          this.cate = res || [];
        });
      },
      getList(val) {
        var type = val || this.$route.params.t || 0;
        var data = {
          status: type,
          nowPage: this.currentPage || 1,
          pageNumber: this.pageSize,
        };
        this.$api.depositIndex(data).then(res => {
          this.list = res.list || [];
          this.count = res.count || 0;
          this.loading = false;
        });
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.getList();
      },
      getPayInfo(res) {
        this.qr_code = res.qr_code || '';
        this.order_no = res.order_no || '';
        this.order_id = res.id || 0;
        console.log(res)
        if (res.type === 2) {
          this.changeIsDepositDialog(false);
        } else {
          this.changeIsCartDialog(false);
          localStorage.removeItem('cartGoods');
          this.cartList = JSON.parse(localStorage.getItem('cartGoods') || '[]');
        }
        this.pay_type = res.type || 1;
        this.changeIsPayDialog(true);
      },
      goPay(id) {
        this.paySubmission = true;
        var data = {
          id: id,
          type: 2,
        };
        this.$api.payPay(data).then(res => {
          if (res) {
            this.qr_code = res.qr_code || '';
            this.order_no = res.order_no || '';
            this.order_id = res.id || 0;
            this.pay_type = 2;
            this.changeIsPayDialog(true);
            this.paySubmission = false;
          }
          else {
            setTimeout(() => {
              this.paySubmission = false;
            }, 2000);
          }
        });
      },
      depositDistribute(item){
        this.id = item.id || 0
        this.$api.depositUser(item.id).then(res => {
          this.userData = res.user_data || []
        })
        this.changeIsDepositDistribute(true)
      }
    },
    created() {
      this.getCate();
      this.getList();
      this.$api.userInfo().then(res => {
        if (res) {
          this.userName = res.name || '';
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  .wrap-order {
    width: 100%;
    height: 100vh;

    .header {
      height: 80px;
      font-size: 0;
      line-height: 0;
      background: #fff;
      padding: 10px 0;
      box-sizing: border-box;

      .content {
        display: flex;
      }

      .logo {
        display: flex;
        flex: 1;

        .text {
          font-size: 20px;
          font-weight: bold;
          line-height: 60px;
          padding-left: 10px;
        }
      }

      .fun-list {
        display: flex;
        list-style: none;
        width: 220px;
        text-align: right;

        li {
          font-size: 14px;
          color: #333;
          line-height: 60px;
          padding-left: 30px;
          cursor: pointer;
          position: relative;
          margin-left: 30px;

          .icon {
            width: 28px;
            height: 28px;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -14px;

            img {
              display: block;
            }
          }
        }
      }
    }

    .content {
      width: 1200px;
      margin: 0 auto;
    }

    .order-nav-box {
      position: relative;

      .order-box {
        height: 56px;
        line-height: 56px;
        display: flex;
        background: #fff;
        border-radius: 28px;
        margin: 20px 0;
        width: 580px;
        padding: 0 28px;
        box-sizing: border-box;

        .back-text {
          font-size: 14px;
          height: 58px;
          line-height: 58px;
          position: relative;
          padding-left: 34px;

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            top: 50%;
            margin-top: -12px;
            left: 0;
            background: url("../assets/imgs/back-icon.svg") no-repeat center center;
            background-size: cover;
          }
        }

        .order-nav {
          flex: 1;
          font-size: 0;
          line-height: 0;
          display: inline-block;
          padding-left: 60px;

          li {
            padding-right: 40px;
            display: inline-block;
            position: relative;
            height: 58px;
            line-height: 58px;

            &:last-child {
              padding-right: 0;
            }

            a {
              display: block;
              vertical-align: middle;
              color: #333;
              font-size: 14px;
              position: relative;

              .num {
                display: inline-block;
                content: '';
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: #bf1a2c;
                color: #fff;
                text-align: center;
                line-height: 14px;
                top: -5px;
                right: -10px;
                font-size: 12px;
              }

              &.active {
                color: #0A7BFF;
                font-weight: bold;

                &:after {
                  position: absolute;
                  content: '';
                  display: inline-block;
                  width: 1em;
                  height: 2px;
                  background: #0A7BFF;
                  bottom: 0;
                  left: 50%;
                  margin-left: -.5em;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }

      .deposit-btn {
        width: 124px;
        height: 44px;
        border-radius: 22px;
        line-height: 44px;
        background: #0A7BFF;
        color: #fff;
        position: absolute;
        top: 50%;
        margin-top: -22px;
        right: 0;
        padding-left: 50px;
        box-sizing: border-box;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          left: 20px;
          top: 50%;
          margin-top: -12px;
          background: url("../assets/imgs/deposit-icon-white.png") no-repeat center center;
          background-size: cover;
        }
      }
    }


    .order-list {
      background: #fff;
      width: 100%;
    }

    table {
      width: 100%;

      th {
        height: 30px;
        font-size: 14px;
        line-height: 40px;
        min-height: 40px;
        color: #8c8c8c;
        background: #E3E6EC;
        font-weight: 400;
        box-sizing: border-box;
      }

      tbody {
        .sep-row {
          height: 10px;
          background: #F0F2F5;

          td {
            border: 0;
          }
        }

        .order-num {
          background: #F8F9FA;
          height: 35px;
          line-height: 35px;
          color: #aaa;
          overflow: hidden;

          td {
            border-bottom: 1px solid #f5f5f5;
            position: relative;

            .time {
              display: inline-block;
              color: #8c8c8c;
              padding-left: 20px;
            }

            .number {
              display: inline-block;
              padding-left: 40px;
              color: #8c8c8c;

              span {
                color: #231f20;
              }
            }

            .order-status {
              position: absolute;
              right: 20px;
              font-size: 12px;
              color: #999;
              height: 35px;
              line-height: 35px;
            }
          }
        }

        .order-detail {
          text-align: center;
          vertical-align: top;

          td {
            border: 1px solid #f0f4f7;
            vertical-align: middle;
            font-size: 14px;
            line-height: 30px;
            color: #333;
            padding: 20px 0;
          }

          .confirm-btn {
            padding: 10px 15px;
            background: rgba(10, 123, 255, 0.1);
            color: #0A7BFF;
            border-radius: 4px;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            border: 0;
            font-size: 12px;
            outline: 0;
          }
        }
      }
    }

    .pagination-box {
      text-align: right;
      padding: 20px 0;

      ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #4E87F6; //修改后的背景图颜色
        color: #fff;
      }
    }

    .empty {
      background: #fff;
      height: 720px;

      img {
        width: 250px;
        height: 162px;
        display: block;
        margin: 20% auto 0;
      }

      .msg {
        text-align: center;
        font-size: 14px;
        color: #ccc;
        padding: 20px 0;
      }
    }

  }
</style>
